export const professionList = [
    { value: 'Architecte', label: 'Architecte' },
    { value: 'Artisan', label: 'Artisan' },
    { value: 'Artisane', label: 'Artisane' },
    { value: 'Auteur', label: 'Auteur' },
    { value: 'Auteure', label: 'Auteure' },
    { value: 'Avocat', label: 'Avocat' },
    { value: 'Avocate', label: 'Avocate' },
    { value: 'Boulanger', label: 'Boulanger' },
    { value: 'Boulangère', label: 'Boulangère' },
    { value: 'Caissier ', label: 'Caissier ' },
    { value: 'Caissière', label: 'Caissière' },
    { value: 'Chauffeur', label: 'Chauffeur' },
    { value: 'Chauffeuse', label: 'Chauffeuse' },
    { value: 'Comédien', label: 'Comédien' },
    { value: 'Comédienne', label: 'Comédienne' },
    { value: 'Comptable', label: 'Comptable' },
    { value: 'Concierge', label: 'Concierge' },
    { value: 'Conseiller', label: 'Conseiller' },
    { value: 'Conseillère', label: 'Conseillère' },
    { value: 'Contremaître', label: 'Contremaître' },
    { value: 'Cuisinier', label: 'Cuisinier' },
    { value: 'Cuisinière', label: 'Cuisinière' },
    { value: 'Danseur', label: 'Danseur' },
    { value: 'Danseuse', label: 'Danseuse' },
    { value: 'Directeur', label: 'Directeur' },
    { value: 'Directrice', label: 'Directrice' },
    { value: 'Enseignant', label: 'Enseignant' },
    { value: 'Enseignante', label: 'Enseignante' },
    { value: 'Formateur', label: 'Formateur' },
    { value: 'Formatrice', label: 'Formatrice' },
    { value: 'Géographe', label: 'Géographe' },
    { value: 'Gérant,', label: 'Gérant,' },
    { value: 'Gérante', label: 'Gérante' },
    { value: 'Graphiste', label: 'Graphiste' },
    { value: 'Guide', label: 'Guide' },
    { value: 'Illustrateur', label: 'Illustrateur' },
    { value: 'Illustratrice', label: 'Illustratrice' },
    { value: 'Infirmier', label: 'Infirmier' },
    { value: 'Infirmière', label: 'Infirmière' },
    { value: 'Ingénieur', label: 'Ingénieur' },
    { value: 'Ingénieure', label: 'Ingénieure' },
    { value: 'Jardinier', label: 'Jardinier' },
    { value: 'Jardinière', label: 'Jardinière' },
    { value: 'Journaliste', label: 'Journaliste' },
    { value: 'Libraire', label: 'Libraire' },
    { value: 'Manutentionnaire', label: 'Manutentionnaire' },
    { value: 'Médecin', label: 'Médecin' },
    { value: 'Musicien', label: 'Musicien' },
    { value: 'Musicienne', label: 'Musicienne' },
    { value: 'Ouvrier agricole', label: 'Ouvrier agricole' },
    { value: 'Ouvrière agricole', label: 'Ouvrière agricole' },
    { value: 'Ouvrier ', label: 'Ouvrier ' },
    { value: 'Ouvrière ', label: 'Ouvrière ' },
    { value: 'Pâtissier', label: 'Pâtissier' },
    { value: 'Pâtissière', label: 'Pâtissière' },
    { value: 'Professeur', label: 'Professeur' },
    { value: 'Psychologue', label: 'Psychologue' },
    { value: 'Réalisateur', label: 'Réalisateur' },
    { value: 'Réalisatrice', label: 'Réalisatrice' },
    { value: 'Serveur', label: 'Serveur' },
    { value: 'Serveuse', label: 'Serveuse' },
    { value: 'Traducteur', label: 'Traducteur' },
    { value: 'Traductrice', label: 'Traductrice' },
    { value: 'Vendeur', label: 'Vendeur' },
    { value: 'Vendeuse', label: 'Vendeuse' },
    { value: 'Dentiste', label: 'Dentiste' },
    { value: 'Pharmacien Pharmacienne  ', label: 'Pharmacien Pharmacienne  ' },
    { value: 'Journaliste', label: 'Journaliste' },
    { value: 'Photographe', label: 'Photographe' },
    { value: 'Informaticienne   Informaticien', label: 'Informaticienne   Informaticien' },
    { value: 'Comptable', label: 'Comptable' },
    { value: 'Coiffeuse', label: 'Coiffeuse' },
    { value: 'Chanteuse', label: 'Chanteuse' },
    { value: 'Chanteur', label: 'Chanteur' },
    { value: 'Actrice', label: 'Actrice' },
    { value: 'Acteur', label: 'Acteur' },
    { value: 'Producteur télé', label: 'Producteur télé' },
    { value: 'Productrice télé', label: 'Productrice télé' },
    { value: 'Chef d’entreprise', label: 'Chef d’entreprise' },
    { value: 'Directeur d’entreprise', label: 'Directeur d’entreprise' },
    { value: 'Directrice d’entreprise', label: 'Directrice d’entreprise' },
    { value: 'Chef cuisinier', label: 'Chef cuisinier' },
    { value: 'Chef cuisinière', label: 'Chef cuisinière' },
    { value: 'Maître restaurateur', label: 'Maître restaurateur' },
    { value: 'Maître restauratrice', label: 'Maître restauratrice' },
    { value: 'Monteur vidéo', label: 'Monteur vidéo' },
    { value: 'Juriste', label: 'Juriste' },
    { value: 'Animateur culturel', label: 'Animateur culturel' },
    { value: 'Animatrice culturelle', label: 'Animatrice culturelle' },
    { value: 'Animateur radio', label: 'Animateur radio' },
    { value: 'Animatrice radio', label: 'Animatrice radio' },
    { value: 'Animateur télé', label: 'Animateur télé' },
    { value: 'Animatrice télé', label: 'Animatrice télé' },
    { value: 'Spécialiste des ressources humaines', label: 'Spécialiste des ressources humaines' },
    { value: 'Promoteur ', label: 'Promoteur ' },
    { value: 'Géographe', label: 'Géographe' },
    { value: 'Libraire', label: 'Libraire' },
    { value: 'Psychologue', label: 'Psychologue' },
    { value: 'Chirurgien', label: 'Chirurgien' },
    { value: 'Chirurgienne', label: 'Chirurgienne' },
    { value: 'Écrivain', label: 'Écrivain' },
    { value: 'Mannequin', label: 'Mannequin' },
    { value: 'Compositeur', label: 'Compositeur' },
    { value: 'Pilote', label: 'Pilote' },
    { value: 'Scientifique', label: 'Scientifique' },
    { value: 'Modéliste', label: 'Modéliste' },
    { value: 'Styliste', label: 'Styliste' },
    { value: 'Agent immobilier', label: 'Agent immobilier' },
    { value: 'Rédacteur', label: 'Rédacteur' },
    { value: 'Rédactrice', label: 'Rédactrice' },
    { value: 'Reporter', label: 'Reporter' },
    { value: 'Documentaliste', label: 'Documentaliste' },
    { value: 'Opticien', label: 'Opticien' },
    { value: 'Opticienne', label: 'Opticienne' },
    { value: 'Nutritionniste', label: 'Nutritionniste' },
    { value: 'Dramaturge', label: 'Dramaturge' },
    { value: 'Esthéticienne', label: 'Esthéticienne' },
    { value: 'Esthéticien', label: 'Esthéticien' },
    { value: 'Footballeur', label: 'Footballeur' },
    { value: 'Programmeur', label: 'Programmeur' },
    { value: 'Cinéaste', label: 'Cinéaste' },
    { value: 'Dessinateur', label: 'Dessinateur' },
    { value: 'Archéologue', label: 'Archéologue' },
    { value: 'Sportif', label: 'Sportif' },
    { value: 'Sportive', label: 'Sportive' },
    { value: 'Ingénieur', label: 'Ingénieur' },
    { value: 'Arbitre', label: 'Arbitre' },
    { value: 'Banquier', label: 'Banquier' },
    { value: 'Banquière', label: 'Banquière' },
    { value: 'Secouriste', label: 'Secouriste' },
    { value: 'Pâtissier', label: 'Pâtissier' },
    { value: 'Pâtissière', label: 'Pâtissière' },
    { value: 'Sommelier', label: 'Sommelier' },
    { value: 'Sommelière', label: 'Sommelière' },
    { value: 'Cameraman', label: 'Cameraman' },
    { value: 'Esthéticien', label: 'Esthéticien' },
    { value: 'Esthéticienne', label: 'Esthéticienne' },
    { value: 'Pédiatre', label: 'Pédiatre' },
    { value: 'Ophtalmologue', label: 'Ophtalmologue' },
]

export const countryList = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Afrique du Sud', label: 'Afrique du Sud' },
    { value: 'Albanie', label: 'Albanie' },
    { value: 'Algérie', label: 'Algérie' },
    { value: 'Allemagne', label: 'Allemagne' },
    { value: 'Andorre', label: 'Andorre' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antigua et Barbuda', label: 'Antigua et Barbuda' },
    { value: 'Arabie saoudite', label: 'Arabie saoudite' },
    { value: 'Argentine', label: 'Argentine' },
    { value: 'Arménie', label: 'Arménie' },
    { value: 'Australie', label: 'Australie' },
    { value: 'Autriche', label: 'Autriche' },
    { value: 'Azerbaïdjan', label: 'Azerbaïdjan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrein', label: 'Bahrein' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbade', label: 'Barbade' },
    { value: 'Belgique', label: 'Belgique' },
    { value: 'Bélize', label: 'Bélize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bhoutan', label: 'Bhoutan' },
    { value: 'Biélorussie', label: 'Biélorussie' },
    { value: 'Bolivie', label: 'Bolivie' },
    { value: 'Bosnie-Herzégovine', label: 'Bosnie-Herzégovine' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brésil', label: 'Brésil' },
    { value: 'Brunei', label: 'Brunei' },
    { value: 'Bulgarie', label: 'Bulgarie' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodge', label: 'Cambodge' },
    { value: 'Cameroun', label: 'Cameroun' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cap Vert', label: 'Cap Vert' },
    { value: 'Centrafrique', label: 'Centrafrique' },
    { value: 'Chili', label: 'Chili' },
    { value: 'Chine', label: 'Chine' },
    { value: 'Chypre', label: 'Chypre' },
    { value: 'Colombie', label: 'Colombie' },
    { value: 'Comores', label: 'Comores' },
    { value: 'Congo démocratique', label: 'Congo démocratique' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Corée du Nord', label: 'Corée du Nord' },
    { value: 'Corée du Sud', label: 'Corée du Sud' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Côte dIvoire', label: 'Côte dIvoire' },
    { value: 'Croatie', label: 'Croatie' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Danemark', label: 'Danemark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominique', label: 'Dominique' },
    { value: 'République Dominicaine', label: 'République Dominicaine' },
    { value: 'Egypte', label: 'Egypte' },
    { value: 'Emirats Arabes Unis', label: 'Emirats Arabes Unis' },
    { value: 'Equateur', label: 'Equateur' },
    { value: 'Erythrée', label: 'Erythrée' },
    { value: 'Espagne', label: 'Espagne' },
    { value: 'Estonie', label: 'Estonie' },
    { value: 'Etats-Unis', label: 'Etats-Unis' },
    { value: 'Ethiopie', label: 'Ethiopie' },
    { value: 'Fidji', label: 'Fidji' },
    { value: 'Finlande', label: 'Finlande' },
    { value: 'France', label: 'France' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambie', label: 'Gambie' },
    { value: 'Géorgie', label: 'Géorgie' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Grèce', label: 'Grèce' },
    { value: 'Grenade', label: 'Grenade' },
    { value: 'Groenland', label: 'Groenland' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinée', label: 'Guinée' },
    { value: 'Guinée Bissau', label: 'Guinée Bissau' },
    { value: 'Guinée équatoriale', label: 'Guinée équatoriale' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haïti', label: 'Haïti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hongrie', label: 'Hongrie' },
    { value: 'Inde', label: 'Inde' },
    { value: 'Indonésie', label: 'Indonésie' },
    { value: 'Irak', label: 'Irak' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Irlande', label: 'Irlande' },
    { value: 'Islande', label: 'Islande' },
    { value: 'Israël', label: 'Israël' },
    { value: 'Italie', label: 'Italie' },
    { value: 'Jamaïque', label: 'Jamaïque' },
    { value: 'Japon', label: 'Japon' },
    { value: 'Jordanie', label: 'Jordanie' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kirghizstan', label: 'Kirghizstan' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Koweït', label: 'Koweït' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Lettonie', label: 'Lettonie' },
    { value: 'Liban', label: 'Liban' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libye', label: 'Libye' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lituanie', label: 'Lituanie' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macédoine', label: 'Macédoine' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malaisie', label: 'Malaisie' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malte', label: 'Malte' },
    { value: 'Maroc', label: 'Maroc' },
    { value: 'Marshall', label: 'Marshall' },
    { value: 'Maurice', label: 'Maurice' },
    { value: 'Mauritanie', label: 'Mauritanie' },
    { value: 'Mexique', label: 'Mexique' },
    { value: 'Micronésie', label: 'Micronésie' },
    { value: 'Moldavie', label: 'Moldavie' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolie', label: 'Mongolie' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibie', label: 'Namibie' },
    { value: 'Népal', label: 'Népal' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Norvège', label: 'Norvège' },
    { value: 'Nouvelle Zélande', label: 'Nouvelle Zélande' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Ouganda', label: 'Ouganda' },
    { value: 'Ouzbekistan', label: 'Ouzbekistan' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestine', label: 'Palestine' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papouasie - Nouvelle Guinée', label: 'Papouasie - Nouvelle Guinée' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Pays-Bas', label: 'Pays-Bas' },
    { value: 'Pérou', label: 'Pérou' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pologne', label: 'Pologne' },
    { value: 'Porto Rico', label: 'Porto Rico' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Roumanie', label: 'Roumanie' },
    { value: 'Royaume-Uni', label: 'Royaume-Uni' },
    { value: 'Russie', label: 'Russie' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Christophe et Nevis', label: 'Saint Christophe et Nevis' },
    { value: 'São-Tomé-et-Príncipe', label: 'São-Tomé-et-Príncipe' },
    { value: 'Saint Vincent et les Grenadines', label: 'Saint Vincent et les Grenadines' },
    { value: 'Sainte-Lucie', label: 'Sainte-Lucie' },
    { value: 'Salomon', label: 'Salomon' },
    { value: 'Salvador', label: 'Salvador' },
    { value: 'Saint-Marin', label: 'Saint-Marin' },
    { value: 'Samoa occidentales', label: 'Samoa occidentales' },
    { value: 'Salvador', label: 'Salvador' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'São Tomé et Príncipe', label: 'São Tomé et Príncipe' },
    { value: 'Sénégal', label: 'Sénégal' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapour', label: 'Singapour' },
    { value: 'Slovaquie', label: 'Slovaquie' },
    { value: 'Slovénie', label: 'Slovénie' },
    { value: 'Somalie', label: 'Somalie' },
    { value: 'Somaliland', label: 'Somaliland' },
    { value: 'Soudan', label: 'Soudan' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Suède', label: 'Suède' },
    { value: 'Suisse', label: 'Suisse' },
    { value: 'Surinam', label: 'Surinam' },
    { value: 'Syrie', label: 'Syrie' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Tadjikistan', label: 'Tadjikistan' },
    { value: 'Taïwan', label: 'Taïwan' },
    { value: 'Tanzanie', label: 'Tanzanie' },
    { value: 'Tchad', label: 'Tchad' },
    { value: 'Tchéquie', label: 'Tchéquie' },
    { value: 'Thaïlande', label: 'Thaïlande' },
    { value: 'Tibet', label: 'Tibet' },
    { value: 'Timor Oriental', label: 'Timor Oriental' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinité et Tobago', label: 'Trinité et Tobago' },
    { value: 'Tunisie', label: 'Tunisie' },
    { value: 'Turkménistan', label: 'Turkménistan' },
    { value: 'Turquie', label: 'Turquie' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican', label: 'Vatican' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Yémen', label: 'Yémen' },
    { value: 'Yougoslavie', label: 'Yougoslavie' },
    { value: 'Zambie', label: 'Zambie' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
]

export const domainList=[
    { value: 'Hébergement', label: 'Hébergement' },
    { value: 'Hébergement et services de restauration', label: 'Hébergement et services de restauration' },
    { value: 'Services administratifs et de soutien', label: 'Services administratifs et de soutien' },
    { value: 'Services administratifs, de soutien et de gestion des déchets et d assainissement', label: 'Services administratifs, de soutien et de gestion des déchets et d assainissement' },
    { value: 'Agriculture, foresterie, pêche et chasse', label: 'Agriculture, foresterie, pêche et chasse' },
    { value: 'Transport aérien', label: 'Transport aérien' },
    { value: 'Services de soins de santé ambulatoires', label: 'Services de soins de santé ambulatoires' },
    { value: 'Amusement', label: 'Amusement' },
    { value: 'Le jeu', label: 'Le jeu' },
    { value: 'Loisirs', label: 'Loisirs' },
    { value: 'Jeux de hasard et loisirs', label: 'Jeux de hasard et loisirs' },
    { value: 'Production animale', label: 'Production animale' },
    { value: 'Fabrication de vêtements', label: 'Fabrication de vêtements' },
    { value: 'Arts', label: 'Arts' },
    { value: 'Divertissement et loisirs', label: 'Divertissement et loisirs' },
    { value: 'Fabrication de boissons et de produits du tabac', label: 'Fabrication de boissons et de produits du tabac' },
    { value: 'Radiodiffusion', label: 'Radiodiffusion' },
    { value: 'Marchands de matériaux de construction et de matériel et fournitures de jardin', label: 'Marchands de matériaux de construction et de matériel et fournitures de jardin' },
    { value: 'Fabrication de produits chimiques', label: 'Fabrication de produits chimiques' },
    { value: 'Magasins de vêtements et d accessoires vestimentaires', label: 'Magasins de vêtements et d accessoires vestimentaires' },
    { value: 'Fabrication de produits informatiques et électroniques', label: 'Fabrication de produits informatiques et électroniques' },
    { value: 'Bâtiment', label: 'Bâtiment' },
    { value: 'Construction de bâtiments', label: 'Construction de bâtiments' },
    { value: 'Courriers et messagers', label: 'Courriers et messagers' },
    { value: 'Intermédiation de crédit et activités connexes', label: 'Intermédiation de crédit et activités connexes' },
    { value: 'Production végétale', label: 'Production végétale' },
    { value: 'Traitement des données, hébergement et services associés', label: 'Traitement des données, hébergement et services associés' },
    { value: 'Tourisme', label: 'Tourisme' },
    { value: 'Services d éducation et de santé', label: 'Services d éducation et de santé' },
    { value: 'Services éducatifs', label: 'Services éducatifs' },
    { value: 'Fabrication d équipements, d appareils et de composants électriques', label: 'Fabrication d équipements, d appareils et de composants électriques' },
    { value: 'Magasins d électronique et d appareils électroménagers', label: 'Magasins d électronique et d appareils électroménagers' },
    { value: 'Fabrication de produits métalliques', label: 'Fabrication de produits métalliques' },
    { value: 'Finances et assurances', label: 'Finances et assurances' },
    { value: 'Activités financières', label: 'Activités financières' },
    { value: 'Pêche, chasse et piégeage', label: 'Pêche, chasse et piégeage' },
    { value: 'Fabrication d aliments', label: 'Fabrication d aliments' },
    { value: 'Services de restauration et débits de boissons', label: 'Services de restauration et débits de boissons' },
    { value: 'Magasins d alimentation et de boissons', label: 'Magasins d alimentation et de boissons' },
    { value: 'Foresterie et exploitation forestière', label: 'Foresterie et exploitation forestière' },
    { value: 'Fonds, fiducies et autres véhicules financiers', label: 'Fonds, fiducies et autres véhicules financiers' },
    { value: 'Magasins de meubles et d articles d ameublement', label: 'Magasins de meubles et d articles d ameublement' },
    { value: 'Fabrication de meubles et de produits connexes', label: 'Fabrication de meubles et de produits connexes' },
    { value: 'Stations d essence', label: 'Stations d essence' },
    { value: 'Magasins de marchandises générales', label: 'Magasins de marchandises générales' },
    { value: 'Industries productrices de biens', label: 'Industries productrices de biens' },
    { value: 'Soins de santé et assistance sociale', label: 'Soins de santé et assistance sociale' },
    { value: 'Magasins de produits de santé et de soins personnels', label: 'Magasins de produits de santé et de soins personnels' },
    { value: 'Construction lourde et de génie civil', label: 'Construction lourde et de génie civil' },
    { value: 'Hôpitaux', label: 'Hôpitaux' },
    { value: 'Informations', label: 'Informations' },
    { value: 'Compagnies d assurance et activités connexes', label: 'Compagnies d assurance et activités connexes' },
    { value: 'Publication et diffusion sur Internet', label: 'Publication et diffusion sur Internet' },
    { value: 'Fabrication de cuir et de produits connexes', label: 'Fabrication de cuir et de produits connexes' },
    { value: 'Loisirs et hospitalité', label: 'Loisirs et hospitalité' },
    { value: 'Bailleurs d actifs incorporels non financiers', label: 'Bailleurs d actifs incorporels non financiers' },
    { value: 'Fabrication de machines', label: 'Fabrication de machines' },
    { value: 'Gestion des sociétés et entreprises', label: 'Gestion des sociétés et entreprises' },
    { value: 'Fabrication', label: 'Fabrication' },
    { value: 'Grossistes marchands, biens durables', label: 'Grossistes marchands, biens durables' },
    { value: 'Grossistes marchands, biens non durables', label: 'Grossistes marchands, biens non durables' },
    { value: 'Exploitation minière', label: 'Exploitation minière' },
    { value: 'Extraction minière, exploitation en carrière et extraction de pétrole et de gaz', label: 'Extraction minière, exploitation en carrière et extraction de pétrole et de gaz' },
    { value: 'Fabrication diverse', label: 'Fabrication diverse' },
    { value: 'Divers détaillants en magasin', label: 'Divers détaillants en magasin' },
    { value: 'Autorités monétaires - Banque centrale', label: 'Autorités monétaires - Banque centrale' },
    { value: 'Industries du cinéma et de l enregistrement sonore', label: 'Industries du cinéma et de l enregistrement sonore' },
    { value: 'Concessionnaires de véhicules automobiles et de pièces', label: 'Concessionnaires de véhicules automobiles et de pièces' },
    { value: 'Musées, sites historiques et institutions similaires', label: 'Musées, sites historiques et institutions similaires' },
    { value: 'Musique', label: 'Musique' },
    { value: 'Ressources naturelles et exploitation minière', label: 'Ressources naturelles et exploitation minière' },
    { value: 'Fabrication de produits minéraux non métalliques', label: 'Fabrication de produits minéraux non métalliques' },
    { value: 'Détaillants', label: 'Détaillants' },
    { value: 'Établissements de soins infirmiers et de soins résidentiels', label: 'Établissements de soins infirmiers et de soins résidentiels' },
    { value: 'Extraction de pétrole et de gaz', label: 'Extraction de pétrole et de gaz' },
    { value: 'Autres services d information', label: 'Autres services d information' },
    { value: 'Autres services', label: 'Autres services' },
    { value: 'Fabrication de papier', label: 'Fabrication de papier' },
    { value: 'Arts du spectacle, sports-spectacles et industries connexes', label: 'Arts du spectacle, sports-spectacles et industries connexes' },
    { value: 'Services personnels et de blanchisserie', label: 'Services personnels et de blanchisserie' },
    { value: 'Fabrication de produits du pétrole et du charbon', label: 'Fabrication de produits du pétrole et du charbon' },
    { value: 'Transport par pipeline', label: 'Transport par pipeline' },
    { value: 'Fabrication de produits en plastique et en caoutchouc', label: 'Fabrication de produits en plastique et en caoutchouc' },
    { value: 'Service postal', label: 'Service postal' },
    { value: 'Première transformation des métaux', label: 'Première transformation des métaux' },
    { value: 'Impression et activités de soutien connexes', label: 'Impression et activités de soutien connexes' },
    { value: 'Ménages privés', label: 'Ménages privés' },
    { value: 'Services professionnels et commerciaux', label: 'Services professionnels et commerciaux' },
    { value: 'Services professionnels, scientifiques et techniques', label: 'Services professionnels, scientifiques et techniques' },
    { value: 'Industries de l édition', label: 'Industries de l édition' },
    { value: 'Transport ferroviaire', label: 'Transport ferroviaire' },
    { value: 'Immobilier', label: 'Immobilier' },
    { value: 'Immobilier et location et crédit-bail', label: 'Immobilier et location et crédit-bail' },
    { value: 'Organisations religieuses, subventionnaires, civiques, professionnelles et similaires', label: 'Organisations religieuses, subventionnaires, civiques, professionnelles et similaires' },
    { value: 'Services de location et de crédit-bail', label: 'Services de location et de crédit-bail' },
    { value: 'Réparation et entretien', label: 'Réparation et entretien' },
    { value: 'Commerce de détail', label: 'Commerce de détail' },
    { value: 'Transport panoramique et touristique', label: 'Transport panoramique et touristique' },
    { value: 'Titres, contrats sur matières premières et autres investissements financiers et activités connexes', label: 'Titres, contrats sur matières premières et autres investissements financiers et activités connexes' },
    { value: 'Industries prestataires de services', label: 'Industries prestataires de services' },
    { value: 'Aide sociale', label: 'Aide sociale' },
    { value: 'Entrepreneurs spécialisés', label: 'Entrepreneurs spécialisés' },
    { value: 'Magasins d articles de sport, de loisirs, de livres et de musique', label: 'Magasins d articles de sport, de loisirs, de livres et de musique' },
    { value: 'Activités d appui à l agriculture et à la foresterie', label: 'Activités d appui à l agriculture et à la foresterie' },
    { value: 'Activités de soutien à l exploitation minière', label: 'Activités de soutien à l exploitation minière' },
    { value: 'Activités de soutien au transport', label: 'Activités de soutien au transport' },
    { value: 'Télécommunications', label: 'Télécommunications' },
    { value: 'Usines de textile', label: 'Usines de textile' },
    { value: 'Usines de produits textiles', label: 'Usines de produits textiles' },
    { value: 'Commerce, transport et services publics', label: 'Commerce, transport et services publics' },
    { value: 'Transit et transport terrestre de passagers', label: 'Transit et transport terrestre de passagers' },
    { value: 'Fabrication de matériel de transport', label: 'Fabrication de matériel de transport' },
    { value: 'Transport et entreposage', label: 'Transport et entreposage' },
    { value: 'Transport par camion', label: 'Transport par camion' },
    { value: 'Utilitaires', label: 'Utilitaires' },
    { value: 'Entreposage et stockage', label: 'Entreposage et stockage' },
    { value: 'Services de gestion des déchets et d assainissement', label: 'Services de gestion des déchets et d assainissement' },
    { value: 'Transport par eau', label: 'Transport par eau' },
    { value: 'Marchés électroniques de gros et agents et courtiers', label: 'Marchés électroniques de gros et agents et courtiers' },
    { value: 'Commerce de gros', label: 'Commerce de gros' },
    { value: 'Fabrication de produits en bois', label: 'Fabrication de produits en bois' },
]